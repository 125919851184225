import { autofill, MapboxAddressMinimap } from '@mapbox/search-js-web';
import Alpine from 'alpinejs';
import breakpoint from 'alpinejs-breakpoints'
import collapse from '@alpinejs/collapse'
import flyover from './alpinejs/flyover'
import intersect from '@alpinejs/intersect'
import map from './alpinejs/map'
import mask from '@alpinejs/mask'
import minimap from './alpinejs/minimap'
import Plyr from 'plyr';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

window.Alpine = Alpine;
window.MapboxAutofill = autofill;
window.MapboxAddressMinimap = MapboxAddressMinimap;
window.videoEmbed = new Plyr(document.querySelector('[data-video-embed]'), {
    controls: [
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'fullscreen'
    ],
});

// Bootstrap AlpineJs...
Alpine.plugin(breakpoint);
Alpine.plugin(collapse);
Alpine.plugin(intersect);
Alpine.plugin(mask);

// Map directives...
Alpine.plugin(flyover);
Alpine.plugin(map);
Alpine.plugin(minimap);

Alpine.start();

import './gtm/click-handlers';
import './gtm/event-listener';

// Spotlight...

import './../../vendor/wire-elements/spotlight/public/spotlight.js';
